.plans {
    background-color: lighten($light,2);
    padding: 6px;
    position: relative;
    text-align: center;

    > h3 {
        color: $grayDarker;
        margin-bottom: 24px;
        margin-top: 30px;

        @media screen and (min-width: $tablet) {
            margin-top: 0;
        }
    }
}

.plans-nav {
    position: absolute;
    top: 0;
    left: 0;
    align-items: flex-end;
    display: flex;
    padding: 10px 12px 0;

    h3 {
        color: $grayDarker;
        cursor: pointer;
        font-size: 16px;
        font-weight: 300;
        line-height: 1.25;
        margin: 0;

        &::before {
            background: url(assets/chevron.svg) no-repeat 0 center;
            content: '';
            display: inline-block;
            height: 8px;
            margin-right: 6px;
            padding-top: 10px;
            transform: rotate(-90deg);
            width: 14px;
        }
    }

    button {
        @include button-link;
        @include green-text-hover;

        font-size: 14px;
        font-weight: 500;
        margin-left: 12px;
    }
}

.plans-switch {
    border: 1px solid $grayDark;
    border-radius: 4px;
    line-height: 1.25;
    margin: 20px auto;
    overflow: hidden;
    width: 126px;

    button {
        background: transparent;
        border: 0 none;
        border-radius: 0;
        box-shadow: none;
        color: $grayDark;
        font-size: 12px;
        line-height: 22px;
        padding: 0;
        width: 62px;

        &.active {
            background: $grayDark;
            color: $white;
        }
    }
}

.plans-list {
    display: flex;
    flex-wrap: wrap;
    margin-left: auto;
    margin-right: auto;
    max-width: 820px;

    @media screen and (min-width: $tablet) {
        flex-wrap: nowrap;
    }
}

.plan {
    background: $white;
    border-radius: 10px;
    box-shadow: 0 0 8px 0 rgba(0,0,0,.1);
    flex-basis: 0;
    flex-grow: 1;
    margin: 6px;
    padding: 24px;
    position: relative;
    width: 100%;

    @media screen and (min-width: $tablet) {
        width: auto;
    }

    h4 {
        margin-top: 10px;
    }

    span {
        display: block;
        font-size: 18px;
        font-weight: 500;
        margin-top: 28px;
    }

    ul {
        display: block;
        font-size: 12px;
        line-height: 18px;
        margin-right: 56px;
        margin-top: 24px;
        padding: 0;
        text-align: left;

        li {
            background: url(assets/tick-sm.svg) no-repeat 0 center;
            margin-top: 8px;
            padding-left: 20px;
        }
    }

    button {
        background: $grayDark url(assets/button-cart.svg) no-repeat center center;
        background-size: 23px;
        border: 0 none;
        border-radius: 4px;
        bottom: 24px;
        color: transparent;
        font-size: 0;
        line-height: 40px;
        padding: 0;
        position: absolute;
        right: 24px;
        width: 40px;

        &.purchased,
        &.loading {
            background-color: transparent;
        }

        &.purchased {
            background-image: url(assets/button-purchased-gray.svg);
        }

        &.loading {
            background: transparent;

            &::after {
                @include spin;

                background: url(assets/loading.png) no-repeat center center;
                content: '';
                display: block;
                height: 24px;
                left: 50%;
                margin-left: -12px;
                margin-top: -12px;
                position: absolute;
                top: 50%;
                width: 24px;
            }
        }
    }

    .plan-tag {
        font-size: 11px;
        letter-spacing: .5px;
        line-height: 16px;
        margin-top: 0;
        text-align: center;
        text-transform: uppercase;

        &.plan-tag-popular {
            color: $orange;
        }

        &.plan-tag-best {
            color: $blue;
        }
    }

    .plan-price {
        small {
            color: $grayDark;
            font-size: 12px;
        }
    }
}

.plans-extra {
    color: $gray;
    font-size: 14px;
    margin-bottom: 1em;
    margin-top: 1em;

    label {
        color: $grayDark;
        display: block;
        font-size: 12px;
        margin-top: 4px;

        input {
            margin-right: 6px;
            vertical-align: middle;
        }

        span {
            margin-left: .25em;
        }

        @media screen and (min-width: $tablet) {
            display: inline;
            margin-left: 16px;
            margin-top: 0;
        }

    }
}
