@keyframes slide {
    0% {
        opacity: 0;
        transform: translateY(-20px);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

.notifications {
    max-width: 460px;
    padding: 20px;
    position: fixed;
    right: 0;
    top: 0;
    width: 100%;
    z-index: 1001;

    li {
        animation: slide 0.4s ease;
        border-radius: 10px;
        box-shadow: 0 12px 14px 6px rgba(0,0,0,.1);
        display: block;
        margin-bottom: 28px;
        padding: 20px;
        position: relative;

        &::before {
            content: '';
            float: left;
            height: 32px;
            width: 32px;
        }

        &.error {
            background: #FEE0E0;
            color: #FE6768;

            &::before {
                background-image: url(assets/error.svg)
            }
        }

        &.success {
            background: #74CCCC;
            color: #FFFFFF;

            &::before {
                background-image: url(assets/success.svg)
            }
        }
    }

    h3,
    p {
        margin-left: 56px;
    }

    h3 {
        font-size: 18px;
        font-weight: 500;
        margin-bottom: 8px;
        margin-top: 6px;
    }

    p {
        font-size: 14px;
        margin-bottom: 0;
    }

    button {
        background: url(assets/cross.svg) no-repeat;
        background-size: contain;
        border: 0 none;
        border-radius: 0;
        color: transparent;
        font: 0/0 a;
        height: 12px;
        position: absolute;
        right: 24px;
        top: 24px;
        width: 12px;
    }
}
