.standard {
    @include card;

    .standard-extra-options {
        display: none;
    }

    > form {
        div {
            align-items: center;
            display: flex;
            padding: 8px 16px;

            @media screen and (min-width: $desktop) {
                padding: 16px;
            }
        }

        input {
            border: 0 none;
            color: $grayDarker;
            flex-grow: 1;
            font-size: 16px;
            font-weight: 700;
            padding: 0;

            @media screen and (min-width: $desktop) {
                font-size: 20px;
            }
        }

        .taken-domain {
            flex: 1;
            padding: 0;
            border-top: none;
            display: flex;
            padding: 0;

            &--tag {
                background: #F1F1F1;
                border-radius: 13.5px;
                padding: 0 8px !important;
                width: auto !important;
                margin-left: 0 !important;

                h2 {
                    font-size: 20px;
                    color: #ABACB3;
                    text-align: left;
                    margin: 0;
                }

                .label {
                    margin-right: 0;
                    margin-left: 8px;
                }

                @media screen and (max-width: $desktop) {
                    h2 {
                        font-size: 16px;
                    }
                }
            }
        }

        .search-bar-separator {
            display: block;
            margin: 0 0.5rem;
            width: 1px;
            height: 24px;
            background: #D6D6D6;

            @media screen and (min-width: $desktop) {
                display: none;
            }
        }

        button {
            @include button-link;
            @include orange-text-hover;

            flex-shrink: 0;
            font-size: 14px;
            font-weight: 500;
            white-space: nowrap;

            &[type="submit"] {
                @include image-replace;
                height: 24px;
                width: 24px;
                position: relative;

                &::before {
                    content: "";
                    position: absolute;
                    left: 0;
                    top: 0;
                    background: url(assets/search.svg) no-repeat center center;
                    background-size: 24px;
                    height: 24px;
                    width: 24px;
                    transform: rotate(90deg);
                }

            }

            &.clear {
                @include image-replace;

                height: 24px;
                width: 24px;
                position: relative;

                &::before {
                    content: "";
                    position: absolute;
                    left: 0;
                    top: 0;
                    background: url(assets/clear.svg) no-repeat center center;
                    background-size: 24px;
                    height: 24px;
                    width: 24px;
                }

            }

            &.beast-mode-button {
                display: flex;
                align-items: center;

                &::before {
                    content: "";
                    display: block;
                    background: url(assets/beast.svg) no-repeat center center;
                    background-size: 1em;
                    height: 1em;
                    width: 1em;
                    margin-right: 0.25em;
                }
            }

            &.hns-button {
                border: 2px solid rgba(116, 204, 204, 0.3);
                border-radius: 2rem;
                padding: 0.25rem 0.5rem;
                display: flex;
                align-items: center;
                color: $hns-color;

                &::before {
                    content: "";
                    display: block;
                    background: url(assets/hns.svg) no-repeat center center;
                    background-size: 1em;
                    height: 1em;
                    width: 1em;
                    margin-right: 0.25em;
                }

                &.active {
                    border-color: $hns-color;
                    background: $hns-color;
                    color: $white;

                    &::before {
                        background: url(assets/hns-white.svg) no-repeat center center;
                    }
                }
            }

            &.filter-toggle {
                background: $grayDark;
                border-radius: 4px;
                color: $white;
                display: none;
                font-weight: 400;
                font-size: 16px;
                line-height: 38px;
                margin: 0;
                padding-left: 1em;
                padding-right: 1em;
                position: absolute;
                right: 1em;
            }

            @media screen and (min-width: $desktop) {
                margin-right: 10px;
                
                &:not([type="submit"]) {
                    margin-left: 10px;
                }

                &[type="submit"] {
                    background-size: auto;
                }
            }

            @media screen and (max-width: $desktop) {
                &.hns-button,
                &.beast-mode-button {
                    padding: 4px;
                    border-radius: 50%;

                    span {
                        display: none;
                    }

                    &::before {
                        margin-right: 0;
                    }
                }

                &[type="submit"] {
                    margin-right: 8px;
                }

                &.beast-mode-button {
                    border: 2px solid #f5a091;
                    margin-right: 8px;
                }
            }
        }
    }

    > article,
    > p {
        background: transparent no-repeat 16px center;
        background-size: 28px;
        padding: 12px;
        padding-left: 60px;

        @media screen and (min-width: $desktop) {
            background-position: 20px center;
            padding-left: 72px;
        }
    }

    > article h2,
    > p {
        color: $grayDark;
        font-size: 16px;
        font-weight: 500;
        letter-spacing: .31px;

        @media screen and (min-width: $tablet) {
            line-height: 42px;
        }

        @media screen and (min-width: $desktop) {
            font-size: 32px;
        }
    }

    > article {
        &.available,
        &.purchased {
            background-image: url(assets/tick.svg);
            background-size: auto;

            h2 {
                color: $greenVivid;
            }

            button.available,
            button.financed {
                background-color: $greenVivid;

                &:hover {
                    background-color: darken($greenVivid,7);
                }
            }
        }

        &.purchased {
            background-color: lighten($light,2);
        }

        &.unavailable {
            background-image: url(assets/cross-gray.svg);
        }

        .price {
            strong {
                font-size: 14px;
                line-height: 16px;
            }

            small {
                font-size: 10px;
            }

            @media screen and (min-width: $desktop) {
                strong {
                    font-size: 20px;
                    line-height: 20px;
                }

                small {
                    font-size: 15px;
                }
            }
        }

        .label span {
            bottom: auto;
            margin-bottom: 0;
            margin-top: 12px;
            top: 100%;

            &::after {
                border-bottom-color: #333130;
                border-top-color: transparent;
                bottom: auto;
                top: -16px;
            }
        }
    }

    > p {
        background-image: url(assets/cross-gray.svg);
        border-top: 1px solid $light;
        margin-bottom: 0;
    }

    @media screen and (max-width: 920px) {
        .standard-extra-options {   
            display: inline-block;
            position: relative;
            padding: 0;
               
            &--toggler {
              display: flex;
              align-items: center;
              justify-content: flex-start;
              padding: 12px;
              font-size: 16px;
              color: #333333;
              line-height: 24px;

              span {
                  display: none;
              }
            }

            &--chevron {
                margin-left: 8px;
                margin-right: 0;

                &.active {
                    transform: rotate(180deg);
                }
            }

            &--dropdown {
                position: absolute;
                display: none;
                background: $white;
                top: calc(100% - 10px);
                right: 10px;
                flex-direction: column;
                padding: 8px 0;
                border-radius: 12px;
                box-shadow: 0 0 8px 0 rgba($black, 0.1);
                z-index: 20;

                button {
                    padding: 8px 20px;
                    width: 100%;
                    text-align: left;

                    svg {
                        margin-right: 8px;
                    }

                    &.active {
                        background: $greenLight;
                        color: $green;
                    }

                    &:not(.active):hover {
                        background: $light;
                    }
                }
                

                &.active {
                    display: flex;
                }
            }
        }
    }

    &.standard-sticky {
        &::after {
            content: '';
            display: block;
            height: 40px;

            @media screen and (min-width: $desktop) {
                height: 56px;
            }
        }

        > form {
            background: $white;
            box-shadow: 0 2px 8px 0 rgba($black,.1);
            left: 0;
            position: fixed;
            right: 0;
            top: 0;
            z-index: 100;

            .standard-form {
                margin-left: auto;
                margin-right: auto;
                max-width: 87.5em;
                padding-left: calc(1em + 16px);
                padding-right: calc(1em + 16px);

                @media screen and (min-width: $desktop-xlarge) {
                    padding-left: calc(2em + 16px);
                    padding-right: calc(2em + 16px);

                    button.filter-toggle {
                        display: inline-block;
                    }
                }

                @media screen and (min-width: $desktop-large) {
                    &::after {
                        content: '';
                        flex-shrink: 0;
                        margin-left: 16px;
                        width: 25%;
                    }
                }
            }
        }

        @media screen and (max-width: 920px) {
            .standard-extra-options {
                &--toggler {
                    svg {
                        margin-right: 8px;
                    }

                    span {
                        display: inline-block;
                    }
                }
            }
        }
    }
}
