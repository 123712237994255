.progress-bar {
  width: 100%;
  display: none;
  position: fixed;
  top: 0;
  left: 0;

  div {
    display: block;
    width: 0;
    height: 4px;
    background: #8cc1c1;
    transition: width 1s ease-in-out;
  }

  &.visible {
    display: block;
  }
}