@mixin button-link {
    background: transparent;
    border: 0 none;
    border-radius: 0;
    padding: 0;
}

@mixin green-text-hover {
    color: $green;

    &:hover {
        color: darken($green,8);
    }
}

@mixin orange-text-hover {
    color: $orange;

    &:hover {
        color: darken($orange,8);
    }
}

@mixin card {
    background: $white;
    border-radius: 10px;
    box-shadow: 0 0 8px 0 rgba($black,.1);
    margin-bottom: 16px;
}

@mixin image-replace {
    clip-path: border-box;
    white-space: nowrap;
    text-indent: 100%;
    overflow: hidden;
}

@mixin cross {
    background: url(assets/cross.svg) no-repeat center center;
    background-size: 100%;
    content: '';
    display: inline-block;
    height: 12px;
    margin-left: 6px;
    width: 12px;
}

@mixin tooltip {
    position: relative;

    span {
        background: $dark;
        border-radius: 4px;
        bottom: 100%;
        box-shadow: 0 6px 14px 6px rgba($black,.1);
        color: $grayLight;
        cursor: auto;
        font-size: 14px;
        margin-bottom: 12px;
        left: 50%;
        opacity: 0;
        padding: 16px;
        pointer-events: none;
        position: absolute;
        transform: translate(-50%, -10px);
        transition: opacity .5s, transform .5s;
        width: 280px;
        white-space: normal;
        z-index: 100;

        &::after {
            border: 8px solid transparent;
            border-top-color: $dark;
            bottom: -16px;
            content: '';
            left: 50%;
            margin-left: -8px;
            position: absolute;
        }

        &[style]::after {
            visibility: hidden;
        }

        &.content-loading {
            align-items: center;
            display: flex;
            height: 100px;
            justify-content: center;

            b {
                @include dot-animation;
                animation-delay: .1s;
                margin: 0 5px;

                &:first-child {
                    animation-delay: 0s;
                    margin: 0;
                }

                &:last-child {
                    animation-delay: .2s;
                    margin: 0;
                }
            }
        }
    }

    p, h1, h2, h3, h4, h5, h6 {
        color: $grayLight;
        font-size: 14px !important;
        font-weight: 400 !important;
        white-space: normal !important;
        word-break: unset;
    }

    strong {
        color: $white;
        font-weight: 700;
    }

    p {
        margin-bottom: 12px;

        &:last-child {
            margin-bottom: 0;
        }
    }

    hr {
        border-color: $grayDarker;
        margin: 0 0 12px !important;
        max-width: none;
    }

    a {
        @include green-text-hover;
    }

    &.open,
    &.hoverable:hover {
        span {
            opacity: 1;
            pointer-events: auto;
            transform: translate(-50%, 0);
        }
    }

    @media screen and (min-width: $tablet) {
        span {
            width: 310px;
        }
    }
}

// Animations

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

@mixin spin {
    animation: spin .75s linear infinite;
}

@keyframes fade-in {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@mixin fade-in {
    animation: fade-in .15s ease-out;
}

@mixin fade-in-slow {
    animation: fade-in 1s ease;
}

@keyframes stretch {
    0% {
        transform: scale(.5);
        background-color: $grayLight;
    }
    50% {
        background-color: $grayLight;
    }
    100% {
        transform: scale(1);
        background-color: $white;
    }
}

@mixin dot-animation {
    animation: {
        direction: alternate;
        duration: .5s;
        fill-mode: none;
        iteration-count: infinite;
        name: stretch;
        play-state: running;
        timing-function: ease-out;
    }
    border-radius: 100%;
    display: block;
    height: 10px;
    margin: 0 1px;
    width: 10px;
}
