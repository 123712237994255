.beast {
    @include card;

    // Grid

    form {
        display: grid;
        grid-template-columns: 100%;
    }

    #beast-transform,
    #beast-options {
        order: 2;
    }

    #beast-append {
        order: 3;
    }

    #beast-filters {
        order: 1;
    }

    form > button {
        order: 4;
    }

    @media screen and (min-width: $desktop) {
        form {
            grid-template-columns: 25% 25% 50%;
            grid-template-rows: auto 0.25fr 0.25fr 0.25fr 0.25fr;
        }

        #beast-keywords {
            grid-column-end: span 3;
        }

        #beast-price,
        #beast-append {
            grid-column-end: span 2;
        }

        #beast-transform,
        #beast-options {
            order: 2;
        }

        #beast-append {
            order: 3;
        }

        #beast-filters {
            order: 1;
            grid-row-end: span 3;
        }

        form > button {
            order: 4;
            grid-column-end: span 3;
        }
    }

    //

    header {
        align-items: center;
        display: flex;
        flex-wrap: wrap;
        padding: 9px 140px 9px 12px;
        position: relative;
        background: $beast-mode-header;
        border-radius: 8px 8px 0 0;

        @media screen and (min-width: $desktop) {
            flex-wrap: nowrap;
            padding: 12px 16px;
            position: static;
        }

        h2 {
            font-size: 18px;
            font-weight: 500;
            line-height: 22px;
            margin: 0;
            width: 100%;
            color: $white;

            @media screen and (min-width: $desktop) {
                display: none;
            }
        }

        ul {
            display: block;
            font-size: 12px;
            line-height: 12px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            width: 100%;

            @media screen and (min-width: $desktop) {
                display: flex;
                align-items: center;
                font-size: 14px;
                line-height: 18px;
                color: $white;
                overflow: visible;
                text-overflow: inherit;
                white-space: normal;
                width: auto;
            }

            button {
                @include button-link;
                @include green-text-hover;
                color: $beast-mode-reset;
                font-weight: 300;

                &:hover {
                    color: $white;
                }
            }

            li {
                color: $white;
                display: inline;
                margin-right: .25em;

                &::after {
                    content: ',';
                }

                &:last-child {
                    border-right: 0;
                    display: none;
                    padding-right: 0;
                    margin-right: 0;
                }

                &:nth-last-child(-n+2) {
                    &::after {
                        content: none;
                    }
                }

                @media screen and (min-width: $desktop) {
                    border-right: 1px solid $light;
                    color: inherit;
                    margin-right: 12px;
                    padding-right: 12px;

                    &:last-child {
                        display: inline;
                    }

                    &::after {
                        content: none;
                    }
                }
            }
        }

        div {
            position: absolute;
            display: flex;
            right: 12px;

            button {
                border: 0 none;
                border-radius: 4px;
            }

            .edit {
                background: $green;
                color: $white;
            }

            .disable {
                background: transparent;
                color: transparent;
                font-size: 0;

                &::after {
                    color: $green;
                    content: 'Clear';
                    font-size: 16px;
                    margin-left: 1em;
                }
            }

            .hns-button {
                border: 2px solid rgba(116, 204, 204, 0.3);
                border-radius: 2rem;
                padding: 1px 8px;
                margin-left: 8px;
                display: flex;
                align-items: center;
                color: $hns-color;
                background: $white;

                &::before {
                    content: "";
                    display: block;
                    background: url(assets/hns.svg) no-repeat center center;
                    background-size: 1em;
                    height: 1em;
                    width: 1em;
                    margin-right: 0.25em;
                }

                &.active {
                    border-color: $hns-color;
                    background: $hns-color;
                    color: $white;

                    &::before {
                        background: url(assets/hns-white.svg) no-repeat center center;
                    }
                }
            }

            @media screen and (min-width: $desktop) {
                margin-left: auto;
                position: static;

                .edit {
                    display: none;
                }

                .disable {
                    background: $orange;
                    border: 2px solid $orange;
                    border-radius: 21px;
                    color: $white;
                    font-size: 14px;
                    line-height: 21px;
                    padding: 1px 8px;
                    display: flex;
                    align-items: center;

                    &::before {
                        content: "";
                        display: block;
                        background: url(assets/beast-white.svg) no-repeat center center;
                        background-size: 1em;
                        height: 1em;
                        width: 1em;
                        margin-right: 0.25em;
                    }

                    &::after {
                        content: none;
                    }

                    span {
                        display: none;
                    }
                }
            }
        }
    }

    form {
        > div {
            align-items: center;
            display: flex;
            padding: 24px 16px 16px;

            @media screen and (min-width: $desktop) {
                display: none;
            }

            h2 {
                font-size: 20px;
                font-weight: 700;
                margin: 0;
            }

            button {
                background: url(assets/cross-gray.svg) no-repeat;
                background-size: contain;
                border: 0 none;
                border-radius: 0;
                color: transparent;
                cursor: pointer;
                font: 0/0 a;
                height: 14px;
                margin-left: auto;
                width: 14px;
            }
        }

        > h4 {
            margin-bottom: 0;
            padding: 16px 16px 0;

            @media screen and (min-width: $desktop) {
                display: none;
            }
        }

        > button {
            background: $green;
            border: 0 none;
            border-radius: 4px;
            bottom: 0;
            color: $white;
            font-size: 20px;
            font-weight: 700;
            height: 48px;
            margin: 12px 16px;
            transition: opacity .5s, background-color .5s;
            width: calc(100% - 32px);
            z-index: 2;

            &.loading {
                position: relative;
                background-position: center center;
                color: transparent;
                font-size: 0;
    
                &::after {
                    @include spin;
    
                    background: url(assets/loading-white.svg) no-repeat center center;
                    content: '';
                    display: block;
                    height: 24px;
                    left: 50%;
                    margin-left: -12px;
                    margin-top: -12px;
                    position: absolute;
                    top: 50%;
                    width: 24px;
                }
            }

            @media screen and (min-width: $desktop) {
                position: static;
                width: auto;
                z-index: 0;
            }

            &:hover {
                background: darken($green,7);
            }

            &[disabled] {
                cursor: not-allowed;
                opacity: .3;

                &:hover {
                    background: $green;
                }
            }
        }
    }

    h4 {
        color: $beast-mode-titles;
        font-size: 16px;
        font-weight: 500;
        margin-bottom: 1em;
        width: 100%;

        @media screen and (min-width: $desktop) {
            font-size: 14px;
        }
    }

    .settings {
        background: $white;
        display: none;
        overflow-y: scroll;
        -webkit-overflow-scrolling: touch;

        &.open {
            display: block;
        }

        > .requirement {
            top: 68px;
        }

        fieldset {
            margin: 0;
        }

        @media screen and (min-width: $desktop) {
            display: block;
            position: static;
            overflow: visible;

            > .requirement {
                left: 16px;
                top: 16px;
                transform: translateX(0);

                &::before {
                    left: 24px;
                }
            }
        }
    }

    .toggle {
        display: inline-block;
        line-height: 24px;
        margin-left: 20px;
        vertical-align: middle;

        span {
            color: transparent;
            cursor: pointer;
            display: block;
            font-size: 0;
            height: 24px;
            position: relative;
            width: 42px;

            &::before,
            &::after {
                position: absolute;
                content: '';
            }

            &::before {
                background: $grayLight;
                border-radius: 12px;
                height: 100%;
                transition: background-color .5s;
                width: 100%;
            }

            &::after {
                background: $white;
                border-radius: 9px;
                height: 18px;
                left: 3px;
                top: 3px;
                transform: translateX(0);
                transition: transform .5s;
                width: 18px;
            }
        }

        input {
            display: none;

            &:checked ~ span {
                &::before {
                    background-color: $green;
                }

                &::after {
                    transform: translateX(18px);
                }
            }
        }
    }

    .option {
        cursor: pointer;
        display: block;
        margin-bottom: 14px;
        position: relative;

        span {
            padding-left: 24px;
            display: inline-block;

            &::before {
                background-color: $white;
                background-position: center center;
                background-repeat: no-repeat;
                border: 2px solid $grayLight;
                border-radius: 4px;
                content: '';
                display: inline-block;
                height: 16px;
                left: 0;
                position: absolute;
                top: 0;
                width: 16px;
            }

            &:hover::before {
                border-color: $gray;
            }
        }

        input {
            opacity: 0;
            position: absolute;
            z-index: 1;

            &:checked ~ span {
                &::before {
                    background-image: url(assets/check.svg);
                    background-color: $green;
                    border-color: $green;
                }

                &:hover::before {
                    background-color: darken($green,7);
                    border-color: darken($green,7);
                }
            }
        }

        &:last-child {
            margin-bottom: 0;
        }
    }

    .input-group {
        div,
        input {
            line-height: 36px;
        }

        div {
            border: 1px solid $grayLighter;
            border-radius: 4px;
            display: inline-block;
            padding: 0 .5em;
            transition: border-color .5s;

            &.focus {
                border: 1px solid $blue;
                box-shadow: 0 0 6px 0 $blueLight;
            }
        }

        input {
            background: transparent;
            border: 0;
            margin-left: .5em;
            padding: 0;
            width: 90px;

            @media screen and (min-width: $desktop) {
                width: 100px;
            }
        }

        label {
            display: inline-block;
            margin-left: 8px;
            margin-right: 8px;
            text-transform: lowercase;

            &:first-child {
                display: none;
            }
        }
    }

    .tags {
        border: 1px solid $light;
        border-radius: 4px;
        margin-top: 16px;
        padding: 16px;

        @media screen and (min-width: $desktop) {
            padding: 24px;
        }

        div {
            border-top: 1px solid $light;
            margin-top: 20px;
            padding-left: 76px;
            padding-top: 20px;
            position: relative;

            &:first-child {
                border-top: 0 none;
                margin-top: 0;
                padding-top: 0;

                label {
                    padding-top: 0;
                }
            }
        }

        ul,
        li {
            display: inline-block;
        }

        label,
        button,
        input {
            line-height: 26px;
        }

        label {
            left: 0;
            padding-top: 20px;
            position: absolute;
            top: 0;
        }

        button {
            background: $green;
            border: 0 none;
            border-radius: 13px;
            color: $white;
            display: inline-block;
            font-size: 14px;
            margin-bottom: 10px;
            margin-right: 10px;
            padding: 0 8px;

            &::after {
                @include cross;

                height: 10px;
                vertical-align: middle;
                width: 10px;
            }

            &:hover {
                background: darken($green,7);
            }
        }

        input {
            background: transparent;
            border: 0 none;
            font-size: 14px;
            padding: 0;
        }
    }

    .filters {
        margin: 0;
        overflow: visible;
        position: static;

        .wrapper {
            border: 1px solid $light;
            border-radius: 4px;
            display: flex;
            flex-direction: column;
            min-height: 340px;
            height: 100%;
            margin: 16px;

            @media screen and (min-width: $desktop) {
                border: 0 none;
                border-radius: 0;
                margin: 0;
            }

            > div {
                display: flex;
                padding: 16px;
                border-bottom: 1px solid $beast-mode-separator;

                @media screen and (min-width: $desktop) {
                    padding: 24px;
                }

                input {
                    border: 0 none;
                    line-height: 24px;
                    padding: 0;
                }

                button {
                    @include button-link;
                    @include green-text-hover;
                    line-height: 24px;
                    margin-left: 20px;
                    white-space: nowrap;

                    &:not(.clear) {
                        background: $beast-mode-clear-bg;
                        color: $beast-mode-clear-color;
                        padding: 4px 12px;
                        font-size: 14px;
                        font-weight: 400;
                        border-radius: 24px;

                        &:hover {
                            background: darken($beast-mode-clear-bg, 2);
                        }
                    }

                    &.clear {
                        @include image-replace;

                        background-image: url(assets/clear.svg);
                        background-position: center center;
                        background-repeat: no-repeat;
                        flex-shrink: 0;
                        height: 24px;
                        width: 24px;
                    }
                }
            }
        }

        dl {
            flex: 1 1 auto;
            padding: 0;

            @media screen and (min-width: $desktop) {
                height: 0;
                overflow-y: scroll;
                -webkit-overflow-scrolling: touch;
                padding: 0;
            }

            dt {
                align-items: center;
                display: flex;
                padding: 0 16px;

                @media screen and (min-width: $desktop) {
                    padding: 0 24px;
                }

                h3 {
                    color: $beast-mode-titles;
                    cursor: pointer;
                    flex: 1 1 auto;
                    font-size: 14px;
                    font-weight: normal;
                    line-height: 42px;
                    margin: 0;

                    &:hover {
                        color: darken($beast-mode-titles,7);
                    }
                }

                .select {
                    @include button-link;
                    background: $beast-mode-clear-bg;
                    color: $beast-mode-clear-color;
                    padding: 2px 8px;
                    border-radius: 24px;
                    font-size: 14px;
                    margin-right: 13px;

                    &:hover {
                        background: darken($beast-mode-clear-bg, 2);
                    }
                }

                .collapse {
                    @include image-replace;

                    background: url(assets/chevron.svg) no-repeat center center;
                    border: 0 none;
                    display: inline-block;
                    height: 16px;
                    padding: 0;
                    transform: rotate(180deg);
                    transition: transform .5s;
                    vertical-align: middle;
                    width: 20px;
                }

                &.open {
                    .collapse {
                        transform: rotate(0deg);
                    }
                }
            }

            dd {
                padding: 0 16px;
                border-bottom: 1px solid $beast-mode-separator;

                &.not-found {
                    border-bottom: 0;
                    color: $gray;
                    font-size: 14px;
                }

                @media screen and (min-width: $desktop) {
                    padding: 0 24px;
                }
            }
        }

        ul {
            padding-bottom: 6px;
            padding-top: 6px;

            li {
                display: inline-block;

                button {
                    border: 0;
                    background: $grayDark;
                    border-radius: 12px;
                    color: $white;
                    font-size: 14px;
                    line-height: 24px;
                    margin: 0 10px 10px 0;
                    padding: 0 8px;

                    &:hover {
                        background: $grayDarker;
                    }
                }

                &.selected {
                    button {
                        background: $green;
                        color: $white;

                        &:hover {
                            background: darken($green,7);
                        }
                    }
                }
            }
        }
    }

    #beast-keywords {
        border: 1px solid $beast-mode-separator;
        border-radius: 4px;
        margin: 16px;
        overflow-y: auto;
        padding: 16px;

        @media screen and (min-width: $desktop) {
            border-radius: 0;
            border-width: 0 0 1px;
            margin: 0;
            padding: 24px 32px;
        }

        .wrapper {
            display: flex;
            justify-content: space-between;
            flex-direction: column;
            max-height: 240px;
        }

        ul {
            width: 100%;
            overflow: auto;
        }

        li {
            display: inline-block;

            button {
                background: $grayDarker;
                border: 0 none;
                border-radius: 14px;
                color: $white;
                cursor: pointer;
                display: inline-block;
                font-size: 18px;
                letter-spacing: .2px;
                line-height: 28px;
                margin-bottom: 16px;
                margin-right: 16px;
                max-width: 238px;
                padding: 0 10px;
                text-align: left;
                word-wrap: break-word;

                &::after {
                    @include cross;
                }

                @media screen and (min-width: $tablet) {
                    max-width: 460px;
                }
                @media screen and (min-width: $desktop) {
                    max-width: 800px;
                }
                @media screen and (min-width: $desktop-large) {
                    max-width: 656px;
                }
                @media screen and (min-width: $desktop-xlarge) {
                    max-width: 824px;
                }
            }

            .clear {
                @include button-link;
                @include green-text-hover;

                font-size: 16px;
            }
        }

        .beast-keywords-form {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            flex-grow: 0;

            &.spaced {
                padding-top: 16px;
            }
        }

        textarea {
            background: transparent;
            border: 0 none;
            color: $dark;
            flex-grow: 1;
            font-size: 16px;
            height: 44px;
            letter-spacing: .2px;
            line-height: 22px;
            padding: 0;
            resize: none;

            @media screen and (min-width: $desktop) {
                font-size: 18px;
                height: 22px;
            }
        }

        .upload {
            background: url(assets/upload.svg) no-repeat center center;
            border: 0 none;
            border-radius: 0;
            height: 21px;
            padding: 0;
            position: relative;
            width: 16px;

            span {
                background: $dark;
                border-radius: 4px;
                box-shadow: 0 3px 7px 3px rgba($black,.1);
                color: $grayLighter;
                font-size: 12px;
                margin: -1px 8px 0 0;
                opacity: 0;
                padding: 4px 6px;
                pointer-events: none;
                position: absolute;
                right: 100%;
                top: 0;
                transform: translateX(-10px);
                transition: opacity .5s, transform .5s;
                white-space: nowrap;
                z-index: 100;
            }

            &:hover {
                span {
                    opacity: 1;
                    transform: translateX(0);
                }
            }
        }
    }

    #beast-price {
        border-bottom: 1px solid $beast-mode-separator;
        border-top: 1px solid $beast-mode-separator;
        padding: 16px;

        @media screen and (min-width: $desktop) {
            border: 0 none;
            padding: 32px 32px 0;
        }
    }

    #beast-transform,
    #beast-options {
        border-bottom: 1px solid $beast-mode-separator;
        padding: 16px;

        @media screen and (min-width: $desktop) {
            border-bottom: 0 none;
            padding: 32px;
        }
    }

    #beast-transform {
        @media screen and (min-width: $desktop) {
            padding-right: 12px;
        }

        .info {
            span {
                left: 0;
                transform: translate(-20px, -10px);

                &::after {
                    left: 0;
                    margin-left: 20px;
                }
            }

            &:hover {
                span {
                    transform: translate(-20px, 0);
                }
            }
        }
    }

    #beast-options {
        @media screen and (min-width: $desktop) {
            padding-left: 12px;
        }
    }

    #beast-append {
        border-bottom: 1px solid $beast-mode-separator;
        padding: 16px;

        h4 {
            margin-bottom: 0;
        }

        @media screen and (min-width: $desktop) {
            padding: 0 32px 32px;
        }
    }

    #beast-filters {
        border-bottom: 1px solid $beast-mode-separator;
        position: relative;
        padding: 0;

        h4 {
            margin: 16px;
            width: auto;
        }

        .requirement {
            top: 3px;
        }

        @media screen and (min-width: $desktop) {
            border-left: 1px solid $beast-mode-separator;

            h4 {
                display: none;
            }

            .requirement {
                top: -32px;
            }
        }
    }
}

.requirement {
    @include fade-in;

    background: $blueLight url(assets/info-blue.svg) no-repeat 14px center;
    border-radius: 4px;
    color: $blueDark;
    cursor: pointer;
    font-size: 16px;
    left: 50%;
    line-height: 20px;
    padding: 10px 14px 10px 48px;
    position: absolute;
    top: 0;
    transform: translateX(-50%);
    white-space: nowrap;
    z-index: 1;

    &::before {
        border: 8px solid transparent;
        border-top-color: $blueLight;
        bottom: -16px;
        content: '';
        left: 50%;
        margin-left: -8px;
        position: absolute;
    }

    &::after {
        background: url(assets/cross-blue.svg) no-repeat;
        content: '';
        display: inline-block;
        height: 12px;
        margin-left: 14px;
        width: 12px;
    }
}

.beast-wireframe {
    background: #ffffff;
    border-radius: 10px;
    box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.1);
    margin-bottom: 16px;

    header {
        border-bottom: 1px solid #f2f2f2;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        padding: 9px 12px;
        position: relative;

        @media screen and (max-width: 920px) {
            .bw-title {
                display: block;
                height: 22px;
                width: 120px;
                background: #f0f0f0;
                border-radius: 4px;
            }
        }

        .bw-options {
            width: 25%;
            height: 18px;
            background: #f3f3f3;
            border-radius: 4px;
        }

        .bw-toggler {
            display: block;
            margin-left: auto;
            border-radius: 24px;
            width: 120px;
            height: 32px;
            background: #eeeeee;
            border-radius: 4px;
        }
    }

    .bw-settings {
        display: grid;
        grid-template-columns: 100%;
        padding-bottom: 72px;

        > div {
            display: flex;

            .bw-title {
                background: #e1e1e1;
                border-radius: 4px;
                width: 80px;
                height: 21px;
                margin-bottom: 16px;
            }

            .bw-checkbox {
                height: 17px;
                width: 140px;
                display: flex;
                margin-bottom: 14px;
                
                &::before,
                &::after {
                    content: "";
                    display: block;
                    background: #f4f4f4;
                    border-radius: 4px;
                    height: 100%;
                }

                &::before {
                    width: 17px;
                    margin-right: 7px;
                }

                &::after {
                    width: calc(100% - 20px);
                }
            }

            .bw-prefixer {
                display: block;
                width: 60%;
                height: 24px;
                background: #f5f5f5;
                border-radius: 4px;
            }

            .bw-btn {
                margin: 12px 16px;
                height: 48px;
                width: 100%;
                background: #f0f0f0;
                border-radius: 8px;
            }

            &:nth-child(1) {
                grid-column-end: span 3;
                border-bottom: 1px solid #f2f2f2;
                justify-content: space-between;
                padding: 24px 32px;
                
                .bw-input {
                    display: block;
                    width: 40%;
                    height: 22px;
                    background: #f0f0f0;
                }

                .bw-import {
                    display: block;
                    width: 16px;
                    height: 21px;
                    background: #f0f0f0;
                }
            }

            &:nth-child(2) {
                grid-column-end: span 2;
                padding: 32px 32px 0;
                flex-direction: column;

                .bw-pricerange-title {
                    display: block;
                    width: 120px;
                    height: 21px;
                    background: #f0f0f0;
                    margin-bottom: 16px;
                }

                div {
                    display: flex;
                }

                .bw-pricerange-input-1,
                .bw-pricerange-input-2 {
                    width: 134px;
                    height: 38px;
                    margin-right: 13px;
                    background: #e6e4e4;
                    border-radius: 4px;
                }
            }

            &:nth-child(4),
            &:nth-child(3) {
                order: 2;
                padding: 32px;
                flex-direction: column;
            }

            &:nth-child(5) {
                padding: 0 32px 32px;
                grid-column-end: span 2;
                border-bottom: 1px solid #f2f2f2;
                order: 3;
            }

            &:nth-child(6) {
                grid-row-end: span 3;
                order: 1;
                border-bottom: 1px solid #f2f2f2;
                position: relative;

                @media screen and (min-width: 920px) {
                    border-left: 1px solid #f2f2f2;
                }
            }

            &:nth-child(7) {
                order: 4;
                grid-column-end: span 3;
            }
        }

        @media screen and (min-width: 920px) {
            grid-template-columns: 25% 25% 50%;
            padding-bottom: 0;
        }
    }
}