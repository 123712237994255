*,
*::before,
*::after {
    box-sizing: border-box;
}

ul {
    list-style: none;
}

ul, dl, ol,
li, dt, dd {
    margin: 0;
    padding: 0;
}

em {
    font-style: normal;
}

fieldset {
    border: 0 none;
}

input,
textarea,
select,
button {
    &,
    &:focus {
        box-shadow: none;
        outline: none;
    }
}

label {
    color: $dark;
    font-size: 14px;
    font-weight: 300;
}

article {
    max-width: none;
}
