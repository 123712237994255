#business-name-generator-app {
  .namegen header {
    background-color: #ffffff !important;

    h2, li {
      color: #4d4a48;
    }

    ul button {
      color: #b3afaf;
    }
  }

  @media screen and (min-width: $desktop) {
    .namegen-keywords {
      border-left: none !important;
      border-right: none !important;
    }
  }
}