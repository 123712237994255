.search-tabs-wrapper {
  height: 60px;
  padding: 16px;
  margin: 0 0 0 -16px;
  overflow: hidden;
  scroll-margin-top: 90px;

  .search-tabs {
    display: flex;
    flex-direction: row;
    overflow-x: auto;
    padding: 16px;
    margin: -16px;
    

    button {
      background: $white;
      box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.1);
      border-radius: 10px 10px 0 0;
      border: none;
      padding: 10px 16px;
      display: inline-flex;
      align-items: baseline;
      font-weight: 500;
      cursor: pointer;
      position: relative;
      height: 44px;
      white-space: nowrap;

      &:not(:last-of-type) {
        margin-right: 8px;
      }

      &.active {
        color: $green;
      }

      span {
        margin-left: 5px;
      }

      &:not(.active)::after {
        content: "";
        display: block;
        position: absolute;
        left: 0;
        bottom: -1px;
        width: 100%;
        height: 1px;
        box-shadow: 0 0px 8px 0 rgba(0, 0, 0, 0.5);
      }
    }
  }

  + .beast,
  + .nc-search-picks,
  + main.results {
    border-radius: 0 10px 10px 10px;

    header {
      border-radius: 0 8px 0 0;
    }

    @media screen and (max-width: $desktop) {
      border-radius: 0 0 10px 10px;
    }
  }

  + .beast {
    header {
      background-color: #ffffff !important;
      border-bottom: 1px solid #cccccc;

      h2, li {
        color: #4d4a48;
      }

      ul button {
        color: #b3afaf;
      }
    }
  }
}
