.announcement-container {
  background: #ffffff;
  border-radius: 10px;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.1);
  margin-bottom: 16px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  padding: 16px 12px;

  img {
    width: 56px;
    height: auto;
  }

  &:not(.ready) {
    img {
      background: #f3f3f3;
      border-radius: 4px;
    }

    h2 {
      background: #f3f3f3;
      color: #f3f3f3;
      border-radius: 4px;
    }

    p {
      margin-top: 4px;
      background: #f8f8f8;
      color: #f8f8f8;
      border-radius: 4px;
    }

    .announcement-button {
      background-color: #f3f3f3;
      color: #f3f3f3;
      pointer-events: none;
    }
  }

  @media screen and (max-width: $desktop) {
    justify-content: flex-end;
  }
}

.announcement-content {
  display: flex;
  flex-direction: column;
  margin-right: auto;
  margin-left: 30px;
  margin-bottom: 8px;
  margin-top: 8px;

  h2 {
    margin: 0;
    font-size: 18px;
    font-weight: 500;
    letter-spacing: 0.09px;
  }

  p {
    margin: 0;
    font-size: 14px;
    color: #b3afaf;
  }

  @media screen and (max-width: $desktop) {
    margin-left: 16px;
    margin-bottom: 24px;
    width: calc(100% - 72px);
  }
}

.announcement-button {
  font-size: 16px;
  padding: 10px 16px;
  background-color: #6d6e70;
  border: none;
  border-radius: 4px;
  color: #ffffff;
  text-align: center;

  &:hover {
    background-color: #5b5c5e;
  }
}

.announcement-modal {
  padding: 0;

  .modal-close {
      z-index: 200;
  }

  .modal-body {
      max-height: 100vh;
      max-width: 100vw;
      height: 100%;
      width: 100%;
      border-radius: 0;
      padding: 0 0;
  }

  iframe {
      min-height: calc(100% - 48px);
  }
}