
.info {
    @include tooltip;

    background: url(assets/info.svg) no-repeat center center;
    color: transparent;
    cursor: pointer;
    display: inline-block;
    flex-shrink: 0;
    font-size: 0;
    height: 18px;
    margin-left: 12px;
    position: relative;
    vertical-align: middle;
    width: 18px;
}
