// Icons

$icons-path: "https://d1dijnkjnmzy2z.cloudfront.net/icons";

$products: "easy-wp", "easy-wp-starter", "easy-wp-turbo", "easy-wp-supersonic",
  "ox-private", "ox-starter", "ox-universal", "ssl", "stellar", "stellar-plus",
  "stellar-business", "vpn", "logo-maker", "business-card-maker", "social-media",
  "gsuite", "sitemaker", "premium-dns", "social-media-manager",
  "grow-your-social", "sitelock", "seo-manager";

// Colors

$white: #ffffff;
$black: #000000;
$grayLighter: #e6e4e4;
$grayLight: #b3afaf;
$gray: #999594;
$grayDark: #6d6e70;
$grayDarker: #4d4a48;

$light: #f2f2f2;
$dark: #333130;

$green: #8cc1c1;
$greenLight: #cde4e3;
$greenVivid: #74cccc; // This is the old green shade used in standard mode
$orange: #f14c2e;
$orangeLight: #feb3a5;
$orangeLighter: #feede9;
$blue: #3281e8;
$blueLight: #d6e6fa;
$blueDark: #2d73cf;
$purple: #7674e1;
$yellowLight: #ffeed3;
$yellow: #734700;

$modal-inner: #f8fafb;
$modal-tag: #3fbaaa;

$progress: #18baaa;

$hns-color: #56b3b3;

$beast-mode-header: #575860;
$beast-mode-titles: #2e2e33;
$beast-mode-reset: #d6d6d6;
$beast-mode-separator: #cccccc;
$beast-mode-clear-bg: #eeeeee;
$beast-mode-clear-color: #737480;

$product-colors: (
  premium-dns: #626365,
  sitemaker: #3846f8,
  gsuite: #eeeeee,
  easy-wp: #108f64,
  ox-private: #6651e5,
  ox-universal: #6651e5,
  ox-starter: #6651e5,
  ssl: #78e3a2,
  stellar: #3281e8,
  vpn: #ff671b,
  logo-maker: #0053fe,
  business-card-maker: #ef9a4c,
  social-media-manager: #5f80ff,
  grow-your-social: #4ebab7,
  sitelock: #a9494c,
  email-marketing-tool: #8574F5,
  seo-manager: #cc459b,
);

// Breakpoints

$tablet: 576px;
$desktop: 920px;
$desktop-large: 1024px;
$desktop-xlarge: 1280px;
