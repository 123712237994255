.modal-wrapper {
    align-items: center;
    background: rgba($dark,.75);
    bottom: 0;
    display: flex;
    justify-content: center;
    left: 0;
    padding: 15px;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 1000;
}

.modal-body {
    background: $white;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba($black,.15);
    color: $dark;
    max-height: calc(100vh - 30px);
    max-width: 720px;
    overflow: hidden;
    overflow-y: auto;
    padding: 24px;
    position: relative;
    width: 100%;

    &.has-iframe {
        padding: 5px 0 0;
    }

    iframe {
        min-height: 637px;
        width: 100%;
    }

    @media screen and (min-width: $tablet) {
        padding: 48px;
    }
}

.modal-footer-sticky {
    background: $white;
    bottom: 0;
    box-shadow: 0 0 10px rgba($black,.15);
    left: 50%;
    max-width: 720px;
    padding: 16px 24px;
    position: fixed;
    transform: translateX(-50%);
    width: 100%;

    @media screen and (min-width: $tablet) {
        padding: 24px 48px;
    }
}

.modal-inner {
    background: $modal-inner;
    margin: 24px -24px;
    padding: 32px 24px;

    @media screen and (min-width: $tablet) {
        margin: 48px -48px;
        padding-left: 48px;
        padding-right: 48px;
    }
}

.modal-close {
    background: url(assets/cross-gray.svg) no-repeat;
    background-size: contain;
    border: 0 none;
    border-radius: 0;
    color: transparent;
    cursor: pointer;
    font: 0/0 a;
    height: 14px;
    position: absolute;
    right: 24px;
    top: 24px;
    width: 14px;
}

// Offer modal

.modal-offer {
    padding: 0;

    @media screen and (min-width: $tablet) {
        padding: 15px;
    }

    .modal-body {
        border-radius: 0;
        color: $grayDark;
        height: 100vh;
        max-height: none;
        text-align: left;

        @media screen and (min-width: $tablet) {
            border-radius: 10px;
            height: auto;
            max-height: calc(100vh - 30px);
            padding: 48px;
        }
    }

    h4,
    h5,
    h6 {
        margin: 0;
    }

    h4 {
        margin-bottom: 28px;
    }

    h5 {
        font-size: 40px;
        font-weight: 400;
    }

    h6 {
        font-size: 20px;
    }

    p {
        font-size: 14px;
        margin-bottom: 20px;
        margin-top: 20px;
    }

    label,
    input {
        text-align: left;
    }

    label {
        color: $grayLight;
        display: block;

        &.required::after {
            color: $orange;
            content: '*';
            margin-left: .25em;
        }
    }

    input,
    .input-price,
    .input-group {
        margin-bottom: 20px;
        margin-top: 5px;
        padding: .5em;
    }

    input,
    .input-price {
        background: $white;
        border: 1px solid $grayLighter;
        border-radius: 4px;
        transition: border-color .5s, box-shadow .5s;
        width: 100%;

        &:last-child {
            margin-bottom: 0;
        }

        &.focus,
        &:focus {
            border: 1px solid $blue;
            box-shadow: 0 0 6px 0 $blueLight;
        }

        &.input-invalid {
            border: 1px solid $orange;

            &.focus,
            &:focus {
                box-shadow: 0 0 6px 0 $orangeLight;
            }
        }
    }

    .input-price {
        display: flex;
        padding: 0 .5em;

        &,
        input {
            line-height: 36px;
        }

        input {
            background: transparent;
            border: 0;
            box-shadow: none;
            flex: 1 1 auto;
            margin: 0 0 0 .5em;
            padding: 0;
        }
    }

    .input-error {
        color: $orange;
        display: block;
        font-size: 14px;
        position: relative;
        text-align: left;
        top: -15px;
    }

    .btn-orange {
        background: $orange;
        border: 0 none;
        border-radius: 4px;
        color: $white;
        font-size: 16px;
        font-weight: 700;
        padding: .5em 1em;
        text-align: center;

        &:hover {
            background: darken($orange,7);
        }

        &[disabled],
        &.disabled {
            background: rgba($orange,.5);
        }

        @media screen and (min-width: $tablet) {
            font-size: 18px;
        }
    }

    .btn-white {
        background: $white;
        border: 0 none;
        color: $orange;

        &:hover {
            color: darken($orange,7);
        }

        &:active {
            background: $greenLight;
        }
    }

    .powered-by {
        display: block;
        font-size: 12px;
        font-weight: 700;
    }

    .form-row {
        display: grid;
        grid-row-gap: 20px;
        grid-template-columns: 1fr;
        margin-bottom: 20px;

        @media screen and (min-width: $tablet) {
            grid-column-gap: 30px;
            grid-template-columns: 1fr 1fr;
        }
    }
}

.modal-loading {
    color: $gray;
    display: block;
    padding-bottom: 40px;
    padding-top: 40px;
    text-align: center;

    &::before {
        @include spin;

        background: url(assets/loading.png) no-repeat center;
        content: '';
        display: inline-block;
        height: 24px;
        margin-right: 10px;
        vertical-align: middle;
        width: 24px;
    }
}

// Offer modal for sedo

.partner {
    h4 {
        align-items: center;
        color: $dark;
        display: flex;
        flex-wrap: wrap;
        font-size: 24px;
        font-weight: 700;

        small {
            color: $gray;
            display: block;
            font-size: 16px;
            font-weight: 400;
            width: 100%;
        }

        .name {
            display: flex;
            max-width: 100%;
            white-space: nowrap;

            &__sld {
                overflow: hidden;
                text-overflow: ellipsis;
            }
            &__tld {
                flex-shrink: 0;
            }
        }

        .tag {
            background: $modal-tag;
            border-radius: 12px;
            color: $white;
            display: inline-block;
            flex-shrink: 0;
            font-size: 12px;
            font-weight: 500;
            line-height: 22px;
            margin-left: 12px;
            padding: 2px 8px 0;
            text-transform: uppercase;
            vertical-align: middle;
        }

        @media screen and (min-width: $tablet) {
            font-size: 40px;

            small {
                font-size: 20px;
            }
        }
    }

    .partner-details,
    .partner-form {
        text-align: left;
    }

    .partner-details {
        background: $light;
        border-radius: 4px;
        margin-bottom: 24px;
        padding: 12px 16px;
        position: relative;

        p {
            margin: 0;

            &.powered {
                font-weight: 700;
            }
        }

        @media screen and (min-width: $tablet) {
            margin-bottom: 40px;

            p {
                &.powered {
                    margin-bottom: 6px;
                }
            }
        }
    }

    .partner-success {
        text-align: center;

        img {
            margin-bottom: 24px;
        }

        h5 {
            font-size: 24px;
        }
    }
}

.partner-sedo {
    .partner-details {
        p {
            display: none;
            margin-right: 115px;

            &.powered {
                display: block;
            }
        }

        img {
            float: none;
            height: auto;
            margin: 0;
            position: absolute;
            right: 16px;
            top: 50%;
            transform: translateY(-50%);
            width: 67px;
        }

        @media screen and (min-width: $tablet) {
            p {
                display: block;
                margin-right: 175px;
            }

            img {
                margin-top: 4px;
                width: 120px;
            }
        }
    }

    .partner-form {
        label {
            color: $dark;
            display: block;
            font-size: 20px;
            font-weight: 500;
            margin-bottom: 12px;
        }

        .input-group,
        .input-append {
            align-items: center;
            display: flex;
            flex-wrap: wrap;

            button {
                flex-grow: 1;
                font-weight: 500;
                height: 50px;
                margin-top: 12px;
                line-height: 18px;
            }
        }

        .input-append {
            border: 1px solid $grayLighter;
            border-radius: 6px;
            padding: 16px 0 0;

            button {
                border-radius: 0 0 6px 6px;
                margin: 16px -1px -1px;
            }
        }

        .input-price {
            line-height: 48px;
            margin: 0;
            padding: 0 1em;
        }

        .input-error {
            margin-top: .5em;
            position: static;
        }

        .price {
            text-align: center;
            width: 100%;

            strong,
            small {
                display: block;
                font-weight: 500;
            }

            strong {
                color: $dark;
                font-size: 24px;
            }
            small {
                color: $gray;
                font-size: 14px;
            }
        }

        @media screen and (min-width: $tablet) {
            label {
                margin-bottom: 24px;
            }

            .input-group,
            .input-append {
                flex-wrap: nowrap;

                button {
                    border-radius: 4px;
                    flex-grow: 0;
                    margin-left: auto;
                    margin-top: 0;
                    width: 30%;
                }
            }

            .input-append {
                padding: 24px;
            }

            .input-price {
                margin-right: 24px;
            }

            .price {
                text-align: left;
                width: auto;

                strong {
                    font-size: 32px;
                }
                small {
                    font-size: 16px;
                }
            }
        }
    }

    .partner-warn {
        line-height: 40px;
        margin: 24px 0 0;

        span {
            background: $blueLight;
            border-radius: 4px;
            color: $blueDark;
            display: block;
            font-size: 12px;
            line-height: 16px;
            padding: 12px 24px;

            @media screen and (min-width: $tablet) {
                display: inline;
                font-size: 14px;
                line-height: inherit;
                padding: 12px;
            }
        }
    }
}

//

.modal-upload,
.modal-share,
.modal-bulk {
    h4 {
        color: $dark;
        font-size: 24px;
        font-weight: 700;
        margin-bottom: 12px;
    }

    p {
        font-size: 16px;
        margin-bottom: 30px;
    }

    fieldset {
        border: 1px solid $grayLighter;
        border-radius: 4px;
        height: 62px;
        padding: 12px;
        position: relative;

        button {
            border-radius: 4px;
            font-size: 16px;
            font-weight: 300;
        }
    }
}

.modal-upload,
.modal-bulk {
    .modal-actions {
        margin-top: 40px;
        text-align: right;

        button {
            background: $orange;
            border: 0 none;
            color: $white;
            margin-left: 16px;
            padding: 14px 32px 15px;

            &[disabled] {
                cursor: default;
                opacity: .3;
                pointer-events: none;
            }

            &.primary {
                background: $green;

                &:hover,
                &:active {
                    background: darken($green,7);
                }
            }

            &.secondary {
                background: $white;
                color: $orange;

                &:hover,
                &:active {
                    color: darken($orange,7);
                }

                &:active {
                    background: $orangeLighter;
                }
            }
        }
    }
}

// Upload modal

.modal-upload {
    fieldset {
        height: 62px;

        &::after {
            color: $blue;
            content: 'Drop your files here';
            display: none;
            font-size: 16px;
            font-weight: 500;
            position: absolute;
            text-align: center;
            top: 50%;
            transform: translateY(-50%);
            width: 100%;
        }

        &.dropping {
            border: 1px dashed $blue;
            box-shadow: 0 0 8px 0 $blueLight;

            &::after {
                display: block;
            }

            label {
                opacity: .1;
            }
        }

        label,
        input {
            cursor: pointer;
            width: 100%;
        }

        input {
            height: 36px;
            opacity: 0;
            position: relative;
            z-index: 2;
        }

        label {
            color: $grayLight;
            font-size: 16px;
            font-weight: 500;
            height: 100%;
            left: 0;
            padding: 12px;
            position: absolute;
            top: 0;
            z-index: 1;

            &::before {
                border: 2px solid $blue;
                border-radius: 4px;
                color: $blue;
                content: 'Choose file';
                display: inline-block;
                font-size: 16px;
                font-weight: 300;
                margin-right: 24px;
                padding: 6px 16px;
                text-align: center;
            }

            &.has-file {
                color: $dark;
            }
        }
    }
}

// Share modal

.modal-share {
    fieldset {
        &::after {
            background-image: linear-gradient(270deg,$white 0%,rgba($white,0) 100%);
            content: '';
            height: 34px;
            position: absolute;
            right: calc(40% + 6px);
            top: 12px;
            width: 96px;
        }

        input {
            border: 0 none;
            color: $grayDarker;
            font-size: 16px;
            font-weight: 500;
            height: 34px;
            margin-bottom: 0;
            width: 60%;
        }

        button {
            @include tooltip;

            background: $white;
            border: 2px solid $blue;
            color: $blue;
            line-height: 20px;
            padding: 6px 16px;
            width: 40%;

            span {
                padding: 10px;
                width: auto;
            }
        }

        input.copied {
            + button {
                span {
                    display: block;
                }
            }
        }

        &.loading {
            &::before {
                @include spin;

                background: url(assets/loading-blue.svg) no-repeat center;
                content: '';
                display: inline-block;
                height: 32px;
                margin-right: 10px;
                vertical-align: middle;
                width: 32px;
            }

            input {
                width: calc(60% - 42px);
            }
        }

        @media screen and (min-width: $tablet) {
            &::after {
                right: calc(25% + 6px);
            }

            input {
                width: 75%;
            }

            button {
                width: 25%;
            }

            &.loading {
                input {
                    width: calc(75% - 42px);
                }
            }
        }
    }
}

// Bulk modal

.modal-bulk {
    fieldset {
        line-height: 32px;
        padding: 12px 20px 14px;

        @media screen and (max-width: $tablet) {
            height: 75px;
            padding-left: 15px;
            padding-right: 15px;
        }

        div {
            align-items: baseline;
            color: $gray;
            display: flex;
            font-weight: 500;
            margin: 0;
            text-align: left;

            @media screen and (max-width: $tablet) {
                align-items: center;

                .process {
                    display: block;

                    p {
                        font-weight: 400;
                        margin-bottom: 0;
                    }

                    strong {
                        font-size: 13px;
                        font-weight: 400;
                        margin-left: 0;
                        padding-top: 24px;
                    }
                }
            }
        }

        strong {
            color: $dark;
            font-weight: 500;
            margin-left: .5em;
        }

        em {
            margin-left: auto;

            @media screen and (max-width: $tablet) {
                padding-top: 24px;
                font-size: 13px;
                font-weight: 400;
            }

            &::after {
                @include spin;

                background: url(assets/loading-blue.svg) no-repeat center;
                content: '';
                display: inline-block;
                height: 32px;
                margin-left: 20px;
                vertical-align: middle;
                width: 32px;

                @media screen and (max-width: $tablet) {
                    background-size: 18px;
                    position: absolute;
                    right: 8px;
                    top: 6px;
                }
            }
        }

        &.completed {
            em {
                color: transparent;

                &::after {
                    animation: none;
                    background-image: url(assets/bulk.svg);
                }
            }

            .progress {
                &,
                & span {
                    background: $progress;
                }
            }
        }
    }

    .progress {
        background: $blueLight;
        bottom: 0;
        border-radius: 0 0 4px 4px;
        height: 4px;
        left: 0;
        overflow: hidden;
        position: absolute;
        right: 0;

        span {
            background: $blue;
            bottom: 0;
            height: 4px;
            left: 0;
            position: absolute;
            transform-origin: left center;
            transition: transform .5s;
            width: 100%;
            will-change: transform;
        }
    }

    .alert-max {
        color: #F14C2E;
        font-size: 12px;
        padding-top: 10px;
    }
}

// Confirm modal

.modal-confirm {
    .modal-body {
        max-width: 560px;
        padding: 36px;
        text-align: center;
    }

    .modal-actions {
        button {
            background: $gray;
            border: 0 none;
            color: $white;
            margin: 0 10px;
            width: 30%;

            &:hover,
            &:active {
                background: darken($gray,7);
            }

            &.primary {
                background: $green;

                &:hover,
                &:active {
                    background: darken($green,7);
                }
            }
        }
    }
}

// Iframes (not an actual modal)

.frame-full {
    position: fixed;
    z-index: 100;

    &,
    iframe {
        height: 100%;
        left: 0;
        top: 0;
        width: 100%;
    }

    iframe {
        position: absolute;
    }

    .modal-loading {
        align-items: center;
        background: $white;
        border-radius: 10px;
        display: flex;
        justify-content: center;
        left: 50%;
        padding: 20px;
        position: fixed;
        top: 50%;
        transform: translate(-50%,-50%);
    }
}


// Early access modal
.early-access-modal,
.early-access-header,
.early-access-form {
    display: flex;
    flex-direction: column;
}

.early-access-header {
    span {
        color: #8e8f8f;
        font-size: 20px;
        line-height: 30px;
        font-weight: 500;
    }

    h1 {
        margin-top: 0;
        color: #333;
    }

    h2 {
        color: #333;
        font-size: 20px;
        font-weight: 500;
        margin-top: 0;
        margin-bottom: 1em;
    }

    p {
        color: #8e8f8f;
        font-size: 18px;
    }
}

.early-access-form {
    input {
        color: #495057;
        font-size: 18px;
        font-weight: 300;
        padding: .375rem .75rem;
        background-color: #fff;
        background-clip: padding-box;
        border: 1px solid #ced4da;
        border-radius: .25rem;
        transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
        height: 54px;
        margin-bottom: 0.5em;

        &.invalid {
            border-color: #dc3545;
            margin-bottom: 0;
        }

        &:focus {
            border-color: #80bdff;
            outline: 0;
            box-shadow: 0 0 0 0.2rem rgba(0,123,255,.25);
        }
    }

    .invalid-feedback {
        color: #dc3545;
        margin-bottom: 0.5em;
    }

    button {
        display: inline-block;
        height: 54px;
        margin-top: 0.5em;
        background: #f14722;
        border: none;
        border-radius: 5px;
        text-align: center;
        white-space: nowrap;
        vertical-align: middle;
        padding: .375rem .75rem;
        font-size: 1rem;
        font-weight: 700;
        line-height: 1.5;
        color: #fff;
        border-radius: .25rem;
        transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;

        &.loading {
            color: transparent;
            font-size: 0;
            background: transparent;
            position: relative;

            &::after {
                @include spin;

                background: url(assets/loading.png) no-repeat center center;
                content: '';
                display: block;
                height: 24px;
                left: 50%;
                margin-left: -12px;
                margin-top: -12px;
                position: absolute;
                top: 50%;
                width: 24px;
            }
        }
    }
}
