.beast header,
.standard > form {
  .hns-wrapper {
    position: relative;
    padding: 0;
    right: unset;
  }

  .hns-info {
    position: absolute;
    top: calc(100% + 8px);
    left: 50%;
    transform: translate(-50%, 0);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    background: #FAFCFF;
    box-shadow: 0 0 14px 6px rgba(0,0,0,0.10);
    border-radius: 8px;
    z-index: 1000;
    padding: 24px 32px !important;
    margin: unset;
    right: unset;
    width: auto !important;

    h2 {
      font-family: Helvetica;
      font-weight: normal;
      font-size: 1.25rem;
      color: #4A4A4A;
      line-height: 1.5rem;
      margin-bottom: 0;
    }

    p {
      font-family: Helvetica;
      font-size: 1rem;
      color: #6D6F70;
      text-align: left;
      line-height: 1.5rem;
    }

    img {
      max-width: 100%;
      min-width: 240px;
      margin-bottom: 1.5rem;

      @media screen and (max-width: 768px) {
        min-width: auto;
      }
    }

    &::before {
      content: "";
      display: block;
      border-left: 8px solid transparent;
      border-right: 8px solid transparent;
      border-bottom: 8px solid #fff;
      position: absolute;
      top: -8px;
      left: calc(50% - 4px);
    }

    @media screen and (max-width: $desktop) {
      display: none;
    }
  }

  .hns-info--footer {
    display: flex;
    width: 100% !important;
    align-items: center;
    justify-content: space-between;
    padding: 0 !important;
  
    a {
      color: #6D6E70;
    }

    button {
      background: #6D6E70;
      color: #ffffff;
      padding: 0.5rem 1.5rem;
      border-radius: 0.25rem;
      margin: 0;
    }
  }
}
