.like-suggestion {
  background: #ffffff;
  border-radius: 10px;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.1);
  margin-bottom: 16px;
  padding: 6px 22px;
  display: flex;
  align-items: center;

  p {
    font-size: 16px;
    color: #6D6E70;
    text-align: left;
    line-height: 24px;
    margin: 0;

    a,
    a:hover {
      color: #74cccc;
    }
  }

  strong {
    font-size: 16px;
    font-weight: 400;
    color: #4A4A4A;
    line-height: 30px;
  }

  &--icons {
    margin-left: 12px;
    display: flex;
    align-items: center;

    button {
      padding: 0;
      appearance: none;
      background: none;
      border: none;
      line-height: 0;

      &:not(:last-of-type) {
        margin-right: 10px;
      }

      &.active {
        svg path {
          fill: #74CCCC;
        }
      }
    }
  }
}

.like-suggestion--bg {
  transition: all 0.5s ease-in-out;
  
  &.closed {
    overflow: hidden;
    height: 0;
    opacity: 0;
  }

  &.open {
    opacity: 1;
    height: min-content;
  }
}