$filters: "academic", "adult", "artsculture", "audiovideo", "businesses", "colors", "finance", "fooddrink", "fun", "healthfitness", "international", "lifestyle", "marketingsales", "mediamusic", "organizations", "orless", "personal", "popular", "products", "professional", "realestate", "services", "shopping", "sportshobbies", "technology", "tradesconstruction", "travel";

.results {
    @include card;

    &-loading {
        align-items: center;
        border-top: 1px solid $grayLighter;
        display: flex;
        justify-content: center;
        padding: 32px 12px;

        &::before {
            @include spin;

            background: url(assets/loading.png) no-repeat center center;
            content: '';
            display: block;
            height: 24px;
            margin-right: 12px;
            width: 24px;
        }
    }

    header,
    .filters {
        background-color: $white;
        transition: background-color .5s;
    }

    header {
        align-items: flex-end;
        border-radius: 10px 10px 0 0; // Need this to avoid a bg overflow
        display: flex;
        padding: 16px 12px;

        &:last-child {
            border-radius: 10px; // Only a header? Sometimes it happens...
        }

        h3 {
            color: $grayDarker;
            font-size: 16px;
            font-weight: 300;
            margin: 0 auto 0 0;
        }
    }

    footer {
        border-top: 1px solid $grayLighter;
        padding: 20px 14px;

        button {
            @include button-link;

            background: url(assets/more.svg) no-repeat left center;
            color: $grayDarker;
            font-size: 16px;
            font-weight: 500;
            line-height: 18px;
            padding-left: 28px;
            padding-top: 2px;

            @media screen and (min-width: $desktop) {
                font-size: 18px;
            }

            &:hover {
                text-decoration: underline;
            }
        }
    }

    article {
        &:hover {
            background-color: lighten($light,2);
        }
    }

    .filters {
        overflow-x: scroll;
        -webkit-overflow-scrolling: touch;
        padding: 8px 0 0 8px;
        white-space: nowrap;

        @media screen and (min-width: $desktop) {
            overflow: visible;
            white-space: normal;
        }

        button {
            background-color: $white;
            border: 0 none;
            border-radius: 16px;
            box-shadow: 2px 2px 6px 0 rgba($black,.1);
            color: $grayDarker;
            cursor: pointer;
            font-size: 13px;
            font-weight: 700;
            letter-spacing: -.4px;
            line-height: 30px;
            margin-bottom: 12px;
            margin-right: 12px;
            padding: 2px 8px 0;
            transition: transform .5s;

            &:hover {
                background-color: darken($white,4);
            }

            &::before {
                background: url('#{$icons-path}/filter-popular.svg') no-repeat center center;
                background-size: contain;
                content: '';
                display: inline-block;
                height: 20px;
                margin-bottom: -5px;
                margin-right: 6px;
                width: 20px;
            }
        }

        li {
            display: inline-block;

            &.selected {
                button {
                    background-color: $green;
                    color: $white;

                    &:hover {
                        background-color: darken($green,7);
                    }

                    &::before {
                        background-image: url('#{$icons-path}/filter-popular-light.svg');
                    }
                }
            }

            @each $filter in $filters {
                &.filter-#{$filter} button::before {
                    background-image: url('#{$icons-path}/filter-#{$filter}.svg');
                }
                &.filter-#{$filter}.selected button::before {
                    background-image: url('#{$icons-path}/filter-#{$filter}-light.svg');
                }
            }
        }
    }

    &.results-standard {
        header button {
            @include button-link;

            color: $grayDarker;
            font-size: 15px;
            font-weight: 500;

            &::after {
                background: url(assets/more.svg) no-repeat right center;
                content: '';
                display: inline-block;
                width: 20px;
                height: 20px;
                margin-left: 8px;
                vertical-align: middle;
            }
        }
    }

    &.results-beast {
        header {
            align-items: center;

            button {
                @include button-link;

                color: $dark;
                line-height: 40px;
                padding-left: 16px;
                padding-right: 32px;
                white-space: nowrap;

                &[disabled] {
                    opacity: .5;
                }

                &.cart.disabled {
                    font-size: 0;
                    color: $grayLight;

                    span {
                        font-size: 16px;
                    }
                }

                @media screen and (min-width: $tablet) {
                    margin-left: 24px;
                    white-space: normal;

                    &.share,
                    &.export {
                        background-position: left center;
                        background-repeat: no-repeat;
                        font-size: 14px;
                        padding: 0;
                    }

                    &.share {
                        background-image: url(assets/share.svg);
                        padding-left: 29px;
                    }

                    &.export {
                        background-image: url(assets/export.svg);
                        padding-left: 26px;
                    }

                    &.cart {
                        @include tooltip;

                        background: lighten($light,2) url(assets/button-cart-gray.svg) no-repeat 12px center;
                        background-size: 23px;
                        border: 1px solid $grayLighter;
                        border-radius: 4px;
                        font-size: 16px;
                        overflow: visible;
                        padding: 0 16px 0 48px;

                        &:hover {
                            background-color: $light;
                        }

                        span {
                            line-height: 18px;
                            padding: 10px;
                            white-space: nowrap;
                            width: auto;
                        }

                        &.disabled {
                            font-size: 16px;
                        }
                    }
                }
            }

            .sort-by-results {
                position: relative;

                a {
                    cursor: pointer;
                    text-decoration: none;

                    p {
                        color: #9F9B9A;
                        margin-bottom: 0;

                        span {
                            color: $black;
                        }

                        &::after {
                            background: url(assets/chevron.svg);
                            background-repeat: no-repeat;
                            background-position: center;
                            content: '';
                            display: inline-block;
                            height: 16px;
                            margin: 0 5px;
                            transform: rotate(180deg);
                            transform-origin: center;
                            transition: transform .5s;
                            vertical-align: middle;
                            width: 20px;
                        }
                    }
                }

                div {
                    align-items: baseline;
                    background-color: white;
                    border-radius: 5px;
                    box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.1);
                    display: none;
                    flex-flow: column;
                    left: 40px;
                    position: absolute;
                    padding: 0 25px 0 15px;
                    top: 30px;
                    z-index: 1;

                    button {
                        margin: 0;
                        padding: 0;

                        &.active {
                            color: #79B1B1;
                        }
                    }
                }

                &.open {
                    p {
                        &::after {
                            transform: none;
                        }
                    }

                    div {
                        display: flex;
                    }
                }
            }

            .results-actions {
                position: relative;

                > button {
                    @include button-link;
                    @include image-replace;

                    background: url(assets/actions.svg) no-repeat center center;
                    display: inline-block;
                    height: 24px;
                    width: 24px;

                    @media screen and (min-width: $tablet) {
                        display: none;
                    }
                }

                div {
                    background: $white;
                    border-radius: 4px;
                    box-shadow: 0 4px 10px 0 rgba(51,49,48,.18), 0 1px 5px 0 rgba(51,49,48,.06);
                    opacity: 0;
                    padding-top: 8px;
                    padding-bottom: 8px;
                    pointer-events: none;
                    position: absolute;
                    right: 0;
                    top: 100%;
                    transform: translateY(-10px);
                    transition: opacity .25s, transform .25s;
                    z-index: 10;

                    &.open {
                        opacity: 1;
                        pointer-events: auto;
                        transform: translateY(0);
                    }

                    @media screen and (min-width: $tablet) {
                        align-items: center;
                        box-shadow: none;
                        display: flex;
                        opacity: 1;
                        padding: 0;
                        pointer-events: auto;
                        position: static;
                        transform: none;
                    }
                }
            }
        }

        .results-beast__error {
            background: url(data:image/svg+xml;base64,PHN2ZyBoZWlnaHQ9IjEyIiB2aWV3Qm94PSIwIDAgMTIgMTIiIHdpZHRoPSIxMiIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJtMTEuNzUxMDExOSA5Ljk5NTc5ODM4IDMuNzE2NjY2Ny0zLjcxNjY2NjY2Yy4zMTI2MDc0LS4zMTI2MDczOS40MzQ2OTQ1LS43NjgyNDI1OS4zMjAyNzIyLTEuMTk1MjcyMjMtLjExNDQyMjItLjQyNzAyOTY0LS40NDc5NzAzLS43NjA1Nzc3NS0uODc1LS44NzUtLjQyNzAyOTYtLjExNDQyMjI1LS44ODI2NjQ4LjAwNzY2NDg0LTEuMTk1MjcyMi4zMjAyNzIyM2wtMy43MTY2NjY3IDMuNzE2NjY2NjYtMy43MTY2NjY2NC0zLjcxNjY2NjY2Yy0uNDgzMjQ5MTYtLjQ4MzI0OTE2LTEuMjY2NzUwODUtLjQ4MzI0OTE2LTEuNzUgMC0uNDgzMjQ5MTYuNDgzMjQ5MTUtLjQ4MzI0OTE2IDEuMjY2NzUwODQgMCAxLjc1bDMuNzE2NjY2NjYgMy43MTY2NjY2Ni0zLjcxNjY2NjY2IDMuNzE2NjY2NzJjLS4yMzUxNjAxNy4yMzA0MDg5LS4zNjc2Nzg1OS41NDU3NzU3LS4zNjc2Nzg1OS44NzUgMCAuMzI5MjI0Mi4xMzI1MTg0Mi42NDQ1OTEuMzY3Njc4NTkuODc1LjIzMDQwODk2LjIzNTE2MDEuNTQ1Nzc1NzYuMzY3Njc4NS44NzUuMzY3Njc4NS4zMjkyMjQyMyAwIC42NDQ1OTEwMy0uMTMyNTE4NC44NzUtLjM2NzY3ODVsMy43MTY2NjY2NC0zLjcxNjY2NjcgMy43MTY2NjY3IDMuNzE2NjY2N2MuMjMwNDA5LjIzNTE2MDEuNTQ1Nzc1OC4zNjc2Nzg1Ljg3NS4zNjc2Nzg1cy42NDQ1OTEtLjEzMjUxODQuODc1LS4zNjc2Nzg1Yy4yMzUxNjAyLS4yMzA0MDkuMzY3Njc4Ni0uNTQ1Nzc1OC4zNjc2Nzg2LS44NzUgMC0uMzI5MjI0My0uMTMyNTE4NC0uNjQ0NTkxMS0uMzY3Njc4Ni0uODc1eiIgZmlsbD0iI2IzYWZhZiIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTQgLTQpIi8+PC9zdmc+) no-repeat 16px center;
            background-position: 20px center;
            border-top: 1px solid #f2f2f2;
            font-size: 32px;
            line-height: 42px;
            color: #6d6e70;
            font-weight: 500;
            letter-spacing: .31px;
            background-size: 28px;
            padding: 12px;
            padding-left: 72px;
            margin-bottom: 0;
        }
    }

    &.filtering {
        header,
        .filters {
            background-color: $light;
        }

        header button::after {
            background-image: url(assets/less.svg);
        }
    }
}

.download {
    background: $green;
    bottom: 0;
    color: $white;
    font-size: 12px;
    left: 0;
    padding: 10px;
    position: fixed;
    right: 0;
    z-index: 100;

    span {
        background: rgba($black,.1);
        bottom: 0;
        left: 0;
        position: absolute;
        right: 0;
        top: 0;
        transform-origin: left;
        transform: scaleX(0);
        transition: transform .2s;
    }
}
