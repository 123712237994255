@use "sass:math";
@import 'styles/variables';

.section-affix-app #react-nc-search {
    min-height: calc(100vh - 450px);
}

#react-nc-search {

    color: $dark;

    .wrapper {
        position: relative;
    }

    @import 'styles/mixins';
    @import 'styles/reset';

    @import 'styles/auction';
    @import 'styles/domain';
    @import 'styles/standard';
    @import 'styles/beast';
    @import 'styles/picks';
    @import 'styles/results';
    @import 'styles/intro';
    @import 'styles/plans';
    @import 'styles/hns';
    @import 'styles/tabs';

    @import 'styles/info';
    @import 'styles/notifications';
    @import 'styles/modal';
    @import 'styles/collapse';
    @import 'styles/transitions';
    @import 'styles/progress-bar';
    @import 'styles/announcement';
    @import 'styles/like-suggestions';

    @import 'styles/generator';


    // This must be here to reference the class (section-affix-app) outside the
    // app scope.

    .section-affix-app & {
        .standard.standard-sticky > form div:not(.hns-wrapper) {
            // The media queries below are not using a var, I know it ...
            // It's because the queries follow the viewport sizes defined in the
            // grid used on NC pages.
            @media screen and (min-width: 980px) {
                width: percentage(math.div(1, 1.2));

                &::after {
                    display: none;
                }
            }
            @media screen and (min-width: 1440px) {
                max-width: calc(#{1440px * .75} + 2em);
                width: 75%;
            }
        }
    }
}
