article.auction {
  display: flex;
  align-items: center;
  justify-content: space-between;

  p {
    display: block;
    margin: 0;

    font-size: 14px;
    font-weight: 500;
    letter-spacing: -0.02px;
    line-height: 16px;

    @media screen and (min-width: 920px) {
      font-size: 18px;
      color: #333130;
      letter-spacing: 0;
      text-align: left;
      line-height: 22px;
    }
  }

  h2 {
    @media screen and (min-width: 920px) {
      font-size: 18px;
      font-weight: 400;
      color: #333130;
      line-height: 22px;
    }
  }

  small {
    color: #6d6e70;
    font-size: 10px;
    letter-spacing: -0.17px;
    margin: 0;

    @media screen and (min-width: 920px) {
      font-size: 12px;
      line-height: 16px;
    }
  }

  .nc-button {
    &:hover {
      text-decoration: none;
    }

    &.bid {
      background-image: url("data:image/svg+xml,%3Csvg version='1.1' viewBox='0 0 22 22' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cg transform='translate(-9 -9)' fill='%23fff'%3E%3Cg transform='translate(8 8)'%3E%3Cpath d='m19.235 16.692l0.25205-0.41944c0.81194-1.3512 1.2467-2.8951 1.2467-4.5046 0-4.8659-3.9758-8.8105-8.8803-8.8105-4.9044 0-8.8803 3.9446-8.8803 8.8105 0 4.8659 3.9758 8.8105 8.8803 8.8105 1.3158 0 2.5891-0.28347 3.7538-0.82327l0.33411-0.15486 5.0846 1.4413-1.7908-4.3497zm-7.3816-15.692c5.9943 0 10.854 4.8212 10.854 10.768 0 1.7955-0.44431 3.5284-1.2785 5.0745l1.4241 3.4588c0.41214 1.001-0.071662 2.144-1.0806 2.5529-0.40942 0.16593-0.86314 0.1906-1.2884 0.070056l-4.385-1.243c-1.3302 0.56142-2.7676 0.85515-4.2453 0.85515-5.9943 0-10.854-4.8212-10.854-10.768 0-5.9472 4.8594-10.768 10.854-10.768zm0.86435 3.72h-1.3577v1.4723c-1.7524 0.28194-2.968 1.535-2.968 3.1483 0 3.3676 5.3361 3.1796 5.3361 5.0592 0 0.90846-0.77357 1.347-1.6261 1.347-1.5787 0-2.7943-1.1747-2.7943-1.1747l-1.1051 1.5193s1.1209 1.2374 3.1574 1.4723v1.441h1.3577v-1.441c1.926-0.25061 3.0469-1.6133 3.0469-3.2109 0-3.5085-5.3203-3.1796-5.3203-5.0435 0-0.78316 0.742-1.3314 1.6892-1.3314 1.4051 0 2.4786 0.97111 2.4786 0.97111l0.88408-1.6446s-0.94723-0.98678-2.7785-1.1434v-1.441z'/%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E%0A");
    }
  }

  .auction-domain {
    display: flex;
    align-items: center;

    svg {
      margin-right: 12px;
    }

    @media screen and (min-width: 920px) {
      width: 100%;
    }
  }

  .auction-details {
    display: none;
    flex-direction: column;
    align-items: flex-start;

    p {
      font-size: 16px;
      line-height: 20px;
    }

    @media screen and (min-width: 920px) {
      display: flex;
      margin-left: 40px;
      flex: 150px;
    }
  }

  .auction-pricing {
    margin-left: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;

    @media screen and (min-width: 920px) {
      flex: 150px;

      small {
        display: none;
      }
    }
  }
}
