.fade-transition {
    &-enter {
        opacity: 0;
        &-active {
            opacity: 1;
            transition: opacity 200ms;
        }
    }
    &-exit {
        opacity: 1;
        &-active {
            opacity: 0;
            transition: opacity 200ms;
        }
    }
}
