.intro-step {
    background: $blue;
    border-radius: 4px;
    box-shadow: 0 2px 9px 1px rgba($blue,.2);
    color: $white;
    cursor: pointer;
    font-size: 14px;
    line-height: 24px;
    margin-bottom: 12px;
    padding: 8px 16px 8px 10px;
    position: absolute;
    width: auto;
    z-index: 100;

    strong {
        background: $blueDark;
        border: 1px solid $blueLight;
        border-radius: 12px;
        display: inline-block;
        font-weight: 400;
        height: 24px;
        margin-right: 12px;
        text-align: center;
        width: 24px;
    }

    &::before {
        border: 8px solid transparent;
        border-top-color: $blue;
        bottom: -16px;
        content: '';
        left: 50%;
        margin-left: -8px;
        position: absolute;
    }

    &::after {
        background: url(assets/cross.svg) no-repeat;
        content: '';
        display: inline-block;
        height: 12px;
        margin-left: 18px;
        vertical-align: middle;
        width: 12px;
    }
}
