aside {
    @include card;

    header {
        align-items: flex-end;
        display: flex;
        padding: 16px 12px;

        h3 {
            color: $grayDarker;
            font-size: 16px;
            font-weight: 300;
            margin: 0;
        }

        button {
            @include button-link;
            @include green-text-hover;

            font-size: 14px;
            font-weight: 500;
            margin-left: 12px;
        }
    }

    article {
        max-width: none;

        img {
            display: block;
        }

        &:hover {
            background-color: lighten($light,2);
        }

        .product-info {
            align-items: center;
            display: flex;
        }

        h2 {
            @media screen and (min-width: $tablet) {
                line-height: 28px;
            }

            @media screen and (min-width: $desktop) {
                margin-right: 12px;
            }
        }

        p {
            display: none;
            margin-right: 12px;

            @media screen and (min-width: $desktop) {
                display: block;
            }
        }
    }
}
